import React, { createContext, Dispatch, ReactElement, ReactNode, useReducer } from 'react';
import { GlobalStoreBase } from '../models/GlobalStore';

export type GlobalStoreType = Partial<GlobalStoreBase>;

type GlobalStoreAction =
  | 'REFRESH_CURRENT'
  | 'CLOCK_IN'
  | 'FETCH_SURVEY'
  | 'GET_CLOCK_OUT_INFO'
  | 'FETCH_E_LEARING_SAFTETY_CONFIRM_STATUS'
  | 'REFRESH_CURRENT_CLOCK_IN_PAGE'
  | 'FETCH_SAITAMAPS';

type DispatchGlobalStore = {
  type: GlobalStoreAction;
  payload: GlobalStoreType;
};

/** グローバルステート
React.Context と useReducer で実現しています

@return {Context} GlobalStore

使い方の例

```
import GlobalStore from 'utils/GlobalStore';
const { globalStore, dispatchGlobal } = useContext(GlobalStore);
// 読み取り
console.log(globalStore.current);
// 更新
dispatch({
  type: 'LOAD_SOMETHING'
  payload: {
    content: 'hello someone!!'
  }
})
```
*/
const GlobalStore = createContext({
  globalStore: {} as GlobalStoreType,
  dispatchGlobal: {} as Dispatch<DispatchGlobalStore>,
});

export default GlobalStore;

const reducer = (globalStore: GlobalStoreType = {}, action: DispatchGlobalStore): GlobalStoreType => {
  switch (action.type) {
    // action を追加する場合、 GlobalStoreAction のエイリアスにも追加してください
    case 'REFRESH_CURRENT':
      return {
        ...globalStore,
        current: action.payload.current,
        userId: action.payload.userId,
        clockInAt: action.payload.clockInAt,
        clockOutAt: action.payload.clockOutAt,
        currentLastRefreshedAt: action.payload.currentLastRefreshedAt,
        isConfirmed: action.payload.isConfirmed,
      };
    case 'CLOCK_IN':
      return {
        ...globalStore,
        current: action.payload.current,
        currentLastRefreshedAt: action.payload.currentLastRefreshedAt,
        userId: action.payload.userId,
        clockInAt: action.payload.clockInAt,
      };
    case 'FETCH_SURVEY':
      return {
        ...globalStore,
        surveyUrl: action.payload.surveyUrl,
      };
    case 'GET_CLOCK_OUT_INFO':
      return {
        ...globalStore,
        current: action.payload.current,
        currentLastRefreshedAt: action.payload.currentLastRefreshedAt,
        clockInAt: action.payload.clockInAt,
        targetDate: action.payload.targetDate,
      };
    case 'FETCH_E_LEARING_SAFTETY_CONFIRM_STATUS':
      return {
        ...globalStore,
        eLearningUrl: action.payload.eLearningUrl,
        safetyConfirmUrl: action.payload.safetyConfirmUrl,
      };
    case 'REFRESH_CURRENT_CLOCK_IN_PAGE':
      return {
        ...globalStore,
        current: action.payload.current,
      };
    case 'FETCH_SAITAMAPS':
      return {
        ...globalStore,
        saitamapsUrl: action.payload.saitamapsUrl,
      };
    default:
      return globalStore;
  }
};

const initialState: GlobalStoreType = {};

type GlobalStoreProviderProps = {
  children?: ReactNode;
};

export const GlobalStoreProvider = (props: GlobalStoreProviderProps): ReactElement => {
  const [globalStore, dispatchGlobal] = useReducer(reducer, initialState);
  const { Provider } = GlobalStore;
  return <Provider value={{ globalStore, dispatchGlobal }}>{props?.children}</Provider>;
};
