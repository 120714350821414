import React, { lazy, Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import PageLoading from './PageLoading';

interface AsyncRouteProps extends RouteProps {
  path: string;
  component: ReturnType<typeof lazy>;
}

const AsyncRoute = (props: AsyncRouteProps) => {
  const { path, component: Component } = props;

  if (Component == null) {
    return null;
  }

  return (
    <Route
      path={path}
      exact
      component={() => (
        <Suspense fallback={<PageLoading />}>
          <Component />
        </Suspense>
      )}
    />
  );
};

export default AsyncRoute;
