import React from 'react';
import { Loading } from 'antd-mobile';

const PageLoading = () => (
  <div style={{ margin: '4rem', marginTop: '10rem', textAlign: 'center' }}>
    <p style={{ color: 'var(--adm-color-weak)' }}>読込中</p>
    <Loading />
  </div>
);

export default PageLoading;
