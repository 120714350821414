import React, { CSSProperties, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { GlobalStoreProvider } from 'utils/GlobalStore';
import AsyncRoute from './Routes/AsyncRoute';

const DashboardPage = lazy(() => import('pages/DashboardPage'));
const ClockOutPage = lazy(() => import('pages/ClockOutPage'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));
const LoginPage = lazy(() => import('pages/LoginPage'));
const ClockInPage = lazy(() => import('pages/ClockInPage'));

function AppLayout() {
  const { Content } = Layout;
  const layoutStyles: CSSProperties = {
    background: 'white',
  };

  return (
    <GlobalStoreProvider>
      <Layout>
        <Content style={layoutStyles}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <AsyncRoute path="/dashboard" exact component={DashboardPage} />
            <AsyncRoute path="/clock-out" exact component={ClockOutPage} />
            <AsyncRoute path="/login" exact component={LoginPage} />
            <AsyncRoute path="/clock-in" exact component={ClockInPage} />
            {/* write component here to routing */}
            <AsyncRoute path="*" component={NotFoundPage} />
          </Switch>
        </Content>
      </Layout>
    </GlobalStoreProvider>
  );
}

export default AppLayout;
